import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="jurisdiction"
export default class extends Controller {
    static targets = ["customJurisdictionField", "jurisdictionSelect"];

    connect() {
        this.toggleCustomJurisdiction();
    }

    toggleCustomJurisdiction() {
        const value = this.jurisdictionSelectTarget.value;
        if (value === 'Other') {
            this.customJurisdictionFieldTarget.style.display = 'block';
        } else {
            this.customJurisdictionFieldTarget.style.display = 'none';
        }
    }

    // This method can be triggered by the select change event
    update(event) {
        this.toggleCustomJurisdiction();
    }
}
