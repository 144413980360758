import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static get targets() {
        return ["sidebarContainer", "icon", "link", "sidebarBackdrop", "mainContent"]
    }

    connect() {
        const isExpanded = localStorage.getItem('sidebarExpanded') === 'true';
        if (isExpanded) {
            this.expand();
        } else {
            this.collapse();
        }
        this.setupSidebarEvents();
        this.handleMobileSidebarToggles();
    }

    setupSidebarEvents() {
        this.sidebarContainerTarget.addEventListener('mouseenter', () => {
            if (!this.isSidebarExpanded()) {
                this.expand();
            }
        });

        this.sidebarContainerTarget.addEventListener('mouseleave', () => {
            if (!this.isSidebarExpanded()) {
                this.collapse();
            }
        });
    }


    toggle() {
        const expand = this.sidebarContainerTarget.dataset.expanded !== "1";
        if (expand) {
            this.expand();
        } else {
            this.collapse();
        }
        localStorage.setItem('sidebarExpanded', expand ? 'true' : 'false');
        this.sidebarContainerTarget.dataset.expanded = expand ? '1' : '0';
        this.element.setAttribute('aria-expanded', expand.toString());
    }

    collapse() {
        this.sidebarContainerTarget.classList.remove("w-64");
        this.mainContentTarget.classList.remove("lg:ml-64");
        this.mainContentTarget.classList.add("lg:ml-16");
        this.linkTargets.forEach(link => {
            link.classList.add("sr-only");
        });
    }

    expand() {
        this.sidebarContainerTarget.classList.add("w-64");
        this.mainContentTarget.classList.remove("lg:ml-16");
        this.mainContentTarget.classList.add("lg:ml-64");
        this.linkTargets.forEach(link => {
            link.classList.remove("sr-only");
        });
    }

    isSidebarExpanded() {
        const expanded = localStorage.getItem('sidebarExpanded');
        return expanded === 'true';
    }


    toggleSidebarMobile() {
        const expand = this.sidebarContainerTarget.dataset.expanded !== "1";
        if (expand) {
            this.expand();
        } else {
            this.collapse();
        }
        this.sidebarContainerTarget.dataset.expanded = expand ? '1' : '0';
        this.element.setAttribute('aria-expanded', expand.toString());
    }

    handleMobileSidebarToggles() {
        this.iconTarget.addEventListener('click', () => {
            this.toggleSidebarMobile();
        });

        this.sidebarBackdropTarget.addEventListener('click', () => {
            this.collapse();
        });
    }


}