import {Controller} from "@hotwired/stimulus";

// Connects to data-controller="address"
export default class extends Controller {
    static targets = ["line1", "line2", "city", "state", "postalCode", "country"];

    connect() {
        this.updateStates();
        this.countryTarget.addEventListener("change", this.updateStates.bind(this));
    }

    disconnect() {
        this.countryTarget.removeEventListener("change", this.updateStates.bind(this));
    }

    toggleCopyAddresses(event) {
        const isChecked = event.target.checked;
        if (isChecked) {
            this.copyRegisteredToBusiness();
        } else {
            this.clearBusinessFields();
        }
    }

    copyRegisteredToBusiness() {
        const registeredFields = document.querySelectorAll('[data-address-type="registered"] .form-control');
        const businessFields = document.querySelectorAll('[data-address-type="business"] .form-control');

        registeredFields.forEach((field, index) => {
            businessFields[index].value = field.value;
        });
    }

    clearBusinessFields() {
        const businessFields = document.querySelectorAll('[data-address-type="business"] .form-control');
        businessFields.forEach(field => field.value = "");
    }

    updateStates() {
        const stateSelect = this.stateTarget;
        if (["US", "CA"].includes(this.countryTarget.value)) {
            stateSelect.classList.remove("state-hidden");
            stateSelect.classList.add("state-visible");
        } else {
            stateSelect.classList.remove("state-visible");
            stateSelect.classList.add("state-hidden");
            stateSelect.value = "";
        }
    }
}
