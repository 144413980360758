import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["tableBody", "rowTemplate"];

    connect() {
        console.log("`expense-forecast` Stimulus controller connected.");
        this.formTemplate = document.getElementById('new-row-template').cloneNode(true);
    }

    addNewRow() {
        // const newRow = this.rowTemplateTarget.content.cloneNode(true);
        // this.tableBodyTarget.appendChild(newRow);
        const form = this.formTemplate.cloneNode(true);
        form.style.display = 'block';
        document.body.appendChild(form);
    }
}
