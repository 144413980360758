/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import "flowbite/dist/flowbite.turbo.js";
import "chartkick/chart.js"
import "chartkick/highcharts"
import "mapkick/bundle"
// import "rhino-editor"

require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"


import {Turbo} from "@hotwired/turbo-rails"

// turbo_stream.action(:redirect, comments_path)
Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target)
}

// turbo_stream.advanced_redirect(comments_path)
Turbo.StreamActions.advanced_redirect = function () {
    let url = this.getAttribute('url')
    console.log(url)
    Turbo.visit(url)
}