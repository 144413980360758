import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["latitude", "longitude", "location"];

    connect() {
    }

    handleRetrieve(event) {
        // console.log('Complete event detail:', event.detail);

        const feature = event.detail.features[0]; // Assuming the coordinates are in the first feature
        // console.log('Feature:', feature);

        if (feature && feature.geometry && feature.geometry.coordinates) {
            const coordinates = feature.geometry.coordinates;
            const longitude = coordinates[0];
            const latitude = coordinates[1];
            const formattedLocation = feature.properties.place_formatted;
            // console.log("Selected coordinates:", latitude, longitude, formattedLocation);

            this.latitudeTarget.value = latitude;
            this.longitudeTarget.value = longitude;
            this.locationTarget.value = formattedLocation;
        } else {
            // console.error('No coordinates found in the event detail or incomplete data.');
        }
    }
}

