// app/javascript/controllers/clear_search_controller.js
import {Controller} from "@hotwired/stimulus";
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller {
    connect() {
        this.resetButtonTarget.addEventListener('click', () => {
            this.resetFormAndNavigate();
        });
    }

    resetFormAndNavigate() {
        const form = this.element.querySelector('form');
        if (form) {
            form.reset(); // Reset the entire form
        }
        Turbo.visit(window.location.pathname); // Reloads the current page with Turbolinks
    }

    get resetButtonTarget() {
        return this.element.querySelector('.reset-button');
    }
}
