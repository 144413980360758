import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selectAll", "checkboxes"];

    toggle() {
        this.checkboxesTargets.forEach((checkbox) => {
            checkbox.checked = this.selectAllTarget.checked;
        });
    }
}