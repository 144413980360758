document.addEventListener('dark-mode', () => {
    updateChartTheme();
});

function updateChartTheme() {
    // Determine the current theme
    const isDarkMode = document.documentElement.classList.contains('dark');
    const backgroundColor = isDarkMode ? 'blue' : 'red';

    // Update the chart's background color
    if (window.myChart) {
        window.myChart.setOptions({chart: {backgroundColor: backgroundColor}});
        window.myChart.redraw();
    }
}
