import {Controller} from "@hotwired/stimulus";
import OrgChart from "orgchart/dist/js/jquery.orgchart";
import $ from "jquery";
import html2canvas from 'html2canvas';  // Ensure this import is correct

export default class extends Controller {
    static values = {data: String};

    connect() {
        try {
            let data;
            if (typeof this.dataValue === 'string') {
                try {
                    data = JSON.parse(this.dataValue);
                } catch (parseError) {
                    // console.error("JSON parsing error:", parseError);
                    return;
                }
            } else {
                data = this.dataValue;
            }

            // console.log("Parsed OrgChart data: ", data);

            // Initialize OrgChart using jQuery
            $('#chartContainer').orgchart({
                data: data,
                nodeTitle: 'name',
                nodeContent: 'title',
                createNode: function ($node, data) {
                    $node[0].id = data.id;
                }
            });

            // console.log("OrgChart initialized successfully");

            // Add export button click handler
            $('#exportButton').on('click', () => this.exportChart());
        } catch (error) {
            console.error("Error handling OrgChart data: ", error);
        }
    }

    exportChart() {
        html2canvas(document.querySelector('#chartContainer'), {
            scale: 4 // Increase scale for higher resolution
        }).then(canvas => {
            let link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'orgchart.png';
            link.click();
        });
    }
}

