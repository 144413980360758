// Switches dark/light theme based upon the preference
//
// Set storage value to "localStorage" for saving the preference in the browser
// Otherwise, preference is saved on User profile in database
//
// For localStorage, your head tag should include the following JS to apply the dark class before paint
// document.documentElement.classList.toggle("dark", localStorage.theme === 'dark' || (localStorage.theme !== "light" && window.matchMedia('(prefers-color-scheme: dark)').matches))

import {Controller} from "@hotwired/stimulus";
import {patch} from "@rails/request.js";

export default class extends Controller {
    static values = {
        preference: String,
        storage: {type: String, default: "user"}
    };

    static targets = ["darkIcon", "lightIcon"];

    connect() {
        if (this.storageValue === "localStorage") {
            this.preferenceValue = localStorage.theme;
        }
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", this.preferenceValueChanged.bind(this));
        this.updateIcons();
    }

    disconnect() {
        window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", this.preferenceValueChanged);
    }

    preferenceValueChanged() {
        document.documentElement.classList.toggle('dark', this.preferenceValue === "dark" || (this.preferenceValue === "" && this.systemInDarkMode));
        this.updateIcons();
    }

    get systemInDarkMode() {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    toggleThemeAndSave() {
        this.toggleTheme();
        this.save();
    }

    toggleTheme() {
        if (this.preferenceValue === "dark") {
            this.preferenceValue = "light";
        } else {
            this.preferenceValue = "dark";
        }
        document.documentElement.classList.toggle("dark", this.preferenceValue === "dark");
        this.updateIcons();
    }

    save() {
        this.storageValue === "localStorage" ? this.saveToLocalStorage() : this.saveToUser();
    }

    saveToLocalStorage() {
        localStorage.theme = this.preferenceValue;
    }

    saveToUser() {
        patch("/users", {
            body: {user: {"theme": this.preferenceValue}},
            contentType: "application/json"
        });
    }

    updateIcons() {
        const darkIcon = this.hasDarkIconTarget ? this.darkIconTarget : null;
        const lightIcon = this.hasLightIconTarget ? this.lightIconTarget : null;

        if (darkIcon && lightIcon) {
            if (this.preferenceValue === "dark") {
                darkIcon.classList.remove("hidden");
                lightIcon.classList.add("hidden");
            } else {
                darkIcon.classList.add("hidden");
                lightIcon.classList.remove("hidden");
            }
        }
    }
}

